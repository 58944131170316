export default {
  props: ["info", "justShow"],
  data() {
    return {
      showColor: '#fff',
      Interval3D: null,
      IntervalZirkon: null,
      IntervalImplant: null,
      firstLoading: true,
      treatmentPage: false,
      teeths: [
        //0
        {
          teethId: 48,
          teethColor: {
            fill: "white"
          },
          isSelected: false,
          haveImplant: false
        },
        //1
        {
          teethId: 47,
          teethColor: {
            fill: "white"
          },
          isSelected: false,
          haveImplant: false
        },
        //2
        {
          teethId: 46,
          teethColor: {
            fill: "white"
          },
          isSelected: false,
          haveImplant: false
        },
        //3
        {
          teethId: 45,
          teethColor: {
            fill: "white"
          },
          isSelected: false,
          haveImplant: false
        },
        //4
        {
          teethId: 44,
          teethColor: {
            fill: "white"
          },
          isSelected: false,
          haveImplant: false
        },
        //5
        {
          teethId: 43,
          teethColor: {
            fill: "white"
          },
          isSelected: false,
          haveImplant: false
        },
        //6
        {
          teethId: 42,
          teethColor: {
            fill: "white"
          },
          isSelected: false,
          haveImplant: false
        },
        //7
        {
          teethId: 41,
          teethColor: {
            fill: "white"
          },
          isSelected: false,
          haveImplant: false
        },
        //8
        {
          teethId: 31,
          teethColor: {
            fill: "white"
          },
          isSelected: false,
          haveImplant: false
        },
        //9
        {
          teethId: 32,
          teethColor: {
            fill: "white"
          },
          isSelected: false,
          haveImplant: false
        },
        //10
        {
          teethId: 33,
          teethColor: {
            fill: "white"
          },
          isSelected: false,
          haveImplant: false
        },
        //11
        {
          teethId: 34,
          teethColor: {
            fill: "white"
          },
          isSelected: false,
          haveImplant: false
        },
        //12
        {
          teethId: 35,
          teethColor: {
            fill: "white"
          },
          isSelected: false,
          haveImplant: false
        },
        //13
        {
          teethId: 36,
          teethColor: {
            fill: "white"
          },
          isSelected: false,
          haveImplant: false
        },
        //14
        {
          teethId: 37,
          teethColor: {
            fill: "white"
          },
          isSelected: false,
          haveImplant: false
        },
        //15
        {
          teethId: 38,
          teethColor: {
            fill: "white"
          },
          isSelected: false,
          haveImplant: false
        },
        //16
        {
          teethId: 28,
          teethColor: {
            fill: "white"
          },
          isSelected: false,
          haveImplant: false
        },
        //17
        {
          teethId: 27,
          teethColor: {
            fill: "white"
          },
          isSelected: false,
          haveImplant: false
        },
        //18
        {
          teethId: 26,
          teethColor: {
            fill: "white"
          },
          isSelected: false,
          haveImplant: false
        },
        //19
        {
          teethId: 25,
          teethColor: {
            fill: "white"
          },
          isSelected: false,
          haveImplant: false
        },
        //20
        {
          teethId: 24,
          teethColor: {
            fill: "white"
          },
          isSelected: false,
          haveImplant: false
        },
        //21
        {
          teethId: 23,
          teethColor: {
            fill: "white"
          },
          isSelected: false,
          haveImplant: false
        },
        //22
        {
          teethId: 22,
          teethColor: {
            fill: "white"
          },
          isSelected: false,
          haveImplant: false
        },
        //23
        {
          teethId: 21,
          teethColor: {
            fill: "white"
          },
          isSelected: false,
          haveImplant: false
        },
        //24
        {
          teethId: 11,
          teethColor: {
            fill: "white"
          },
          isSelected: false,
          haveImplant: false
        },
        //25
        {
          teethId: 12,
          teethColor: {
            fill: "white"
          },
          isSelected: false,
          haveImplant: false
        },
        //26
        {
          teethId: 13,
          teethColor: {
            fill: "white"
          },
          isSelected: false,
          haveImplant: false
        },
        //27
        {
          teethId: 14,
          teethColor: {
            fill: "white"
          },
          isSelected: false,
          haveImplant: false
        },
        //28
        {
          teethId: 15,
          teethColor: {
            fill: "white"
          },
          isSelected: false,
          haveImplant: false
        },
        //29
        {
          teethId: 16,
          teethColor: {
            fill: "white"
          },
          isSelected: false,
          haveImplant: false
        },
        //30
        {
          teethId: 17,
          teethColor: {
            fill: "white"
          },
          isSelected: false,
          haveImplant: false
        },
        //31
        {
          teethId: 18,
          teethColor: {
            fill: "white"
          },
          isSelected: false,
          haveImplant: false
        }
      ],
      dots: [
        //0
        {
          dotId: 48,
          dotStyle: {
            fill: "white",
            visibility: "hidden"
          },
          isBridge: false,
          teethOfDot: [47, 48]
        },
        //1
        {
          dotId: 47,
          dotStyle: {
            fill: "white",
            visibility: "hidden"
          },
          isBridge: false,
          teethOfDot: [46, 47]
        },
        //2
        {
          dotId: 46,
          dotStyle: {
            fill: "white",
            visibility: "hidden"
          },
          isBridge: false,
          teethOfDot: [45, 46]
        },
        //3
        {
          dotId: 45,
          dotStyle: {
            fill: "white",
            visibility: "hidden"
          },
          isBridge: false,
          teethOfDot: [44, 45]
        },
        //4
        {
          dotId: 44,
          dotStyle: {
            fill: "white",
            visibility: "hidden"
          },
          isBridge: false,
          teethOfDot: [43, 44]
        },
        //5
        {
          dotId: 43,
          dotStyle: {
            fill: "white",
            visibility: "hidden"
          },
          isBridge: false,
          teethOfDot: [42, 43]
        },
        //6
        {
          dotId: 42,
          dotStyle: {
            fill: "white",
            visibility: "hidden"
          },
          isBridge: false,
          teethOfDot: [41, 42]
        },
        //7
        {
          dotId: 41,
          dotStyle: {
            fill: "white",
            visibility: "hidden"
          },
          isBridge: false,
          teethOfDot: [31, 41]
        },
        //8
        {
          dotId: 32,
          dotStyle: {
            fill: "white",
            visibility: "hidden"
          },
          isBridge: false,
          teethOfDot: [31, 32]
        },
        //9
        {
          dotId: 33,
          dotStyle: {
            fill: "white",
            visibility: "hidden"
          },
          isBridge: false,
          teethOfDot: [32, 33]
        },
        //10
        {
          dotId: 34,
          dotStyle: {
            fill: "white",
            visibility: "hidden"
          },
          isBridge: false,
          teethOfDot: [33, 34]
        },
        //11
        {
          dotId: 35,
          dotStyle: {
            fill: "white",
            visibility: "hidden"
          },
          isBridge: false,
          teethOfDot: [34, 35]
        },
        //12
        {
          dotId: 36,
          dotStyle: {
            fill: "white",
            visibility: "hidden"
          },
          isBridge: false,
          teethOfDot: [35, 36]
        },
        //13
        {
          dotId: 37,
          dotStyle: {
            fill: "white",
            visibility: "hidden"
          },
          isBridge: false,
          teethOfDot: [36, 37]
        },
        //14
        {
          dotId: 38,
          dotStyle: {
            fill: "white",
            visibility: "hidden"
          },
          isBridge: false,
          teethOfDot: [37, 38]
        },
        //15
        {
          dotId: 28,
          dotStyle: {
            fill: "white",
            visibility: "hidden"
          },
          isBridge: false,
          teethOfDot: [27, 28]
        },
        //16
        {
          dotId: 27,
          dotStyle: {
            fill: "white",
            visibility: "hidden"
          },
          isBridge: false,
          teethOfDot: [26, 27]
        },
        //17
        {
          dotId: 26,
          dotStyle: {
            fill: "white",
            visibility: "hidden"
          },
          isBridge: false,
          teethOfDot: [25, 26]
        },
        //18
        {
          dotId: 25,
          dotStyle: {
            fill: "white",
            visibility: "hidden"
          },
          isBridge: false,
          teethOfDot: [24, 25]
        },
        //19
        {
          dotId: 24,
          dotStyle: {
            fill: "white",
            visibility: "hidden"
          },
          isBridge: false,
          teethOfDot: [23, 24]
        },
        //20
        {
          dotId: 23,
          dotStyle: {
            fill: "white",
            visibility: "hidden"
          },
          isBridge: false,
          teethOfDot: [22, 23]
        },
        //21
        {
          dotId: 22,
          dotStyle: {
            fill: "white",
            visibility: "hidden"
          },
          isBridge: false,
          teethOfDot: [21, 22]
        },
        //22
        {
          dotId: 21,
          dotStyle: {
            fill: "white",
            visibility: "hidden"
          },
          isBridge: false,
          teethOfDot: [11, 21]
        },
        //23
        {
          dotId: 12,
          dotStyle: {
            fill: "white",
            visibility: "hidden"
          },
          isBridge: false,
          teethOfDot: [11, 12]
        },
        //24
        {
          dotId: 13,
          dotStyle: {
            fill: "white",
            visibility: "hidden"
          },
          isBridge: false,
          teethOfDot: [12, 13]
        },
        //25
        {
          dotId: 14,
          dotStyle: {
            fill: "white",
            visibility: "hidden"
          },
          isBridge: false,
          teethOfDot: [13, 14]
        },
        //26
        {
          dotId: 15,
          dotStyle: {
            fill: "white",
            visibility: "hidden"
          },
          isBridge: false,
          teethOfDot: [14, 15]
        },
        //27
        {
          dotId: 16,
          dotStyle: {
            fill: "#white",
            visibility: "hidden"
          },
          isBridge: false,
          teethOfDot: [15, 16]
        },
        //28
        {
          dotId: 17,
          dotStyle: {
            fill: "white",
            visibility: "hidden"
          },
          isBridge: false,
          teethOfDot: [16, 17]
        },
        //29
        {
          dotId: 18,
          dotStyle: {
            fill: "white",
            visibility: "hidden"
          },
          isBridge: false,
          teethOfDot: [17, 18]
        }
      ]
    };
  },
  computed: {
    getWorkToDo() {
      return this.$store.getters["dental/getWorkToDo"];
    },
    getMateriel() {
      return this.$store.getters["dental/getMateriel"];
    },
    getImplant() {
      return this.$store.getters["dental/getImplant"];
    },
    getDentalWork() {
      return this.$store.getters["dental/getDentalWork"];
    },
    getOrder() {
      return this.$store.getters["dental/getDentalOrder"];
    },
    getOrderItems() {
      return this.$store.getters["dental/getDentalOrderItems"];
    },
    getDentalWorks() {
      return this.$store.getters["dental/getDentalWorks"];
    },
    getTeethRelations() {
      return this.$store.getters["dental/getTeethRelations"];
    },
    getTotalTreatmentCredit() {
      return this.$store.getters["dental/getTotalTreatmentCredit"];
    },
    getDentalCredit() {
      return this.$store.getters.getDentalCredit;
    }
  },
  methods: {
    controlInfo(showInfo) {
      clearInterval(this.Interval3D);
      this.show3DPrints(true)
      clearInterval(this.IntervalZirkon);
      this.showZirkons(true)
      clearInterval(this.IntervalImplant);
      this.showImplants(true)

      if (showInfo == '3D') {
        this.Interval3D = setInterval(this.show3DPrints, 500)
      } else if (showInfo == 'Zirkon') {
        this.IntervalZirkon = setInterval(this.showZirkons, 500)
      }
      else if (showInfo == 'Implant') {
        this.IntervalImplant = setInterval(this.showImplants, 500)
      }
    },
    show3DPrints(lastSet = false) {
      if (lastSet) {
        this.getOrderItems.forEach(element => {
          if (element.material == 1) {
            const index = this.teeths.findIndex(i => i.teethId == element.teeth)
            if (index != -1) {
              if (this.teeths[index].teethColor.fill == this.showColor) {
                const index2 = this.getDentalWorks.findIndex(i => i.code == element.treatment)
                this.teeths[index].teethColor.fill = this.getDentalWorks[index2].color;
              }
            }
          }
        });
        return
      }
      this.getOrderItems.forEach(element => {
        if (element.material == 1) {
          const index = this.teeths.findIndex(i => i.teethId == element.teeth)
          if (index != -1) {
            if (this.teeths[index].teethColor.fill == this.showColor) {
              const index2 = this.getDentalWorks.findIndex(i => i.code == element.treatment)
              this.teeths[index].teethColor.fill = this.getDentalWorks[index2].color;
            } else {
              this.teeths[index].teethColor.fill = this.showColor;
            }

          }
        }
      });

    },
    showZirkons(lastSet = false) {
      if (lastSet) {
        this.getOrderItems.forEach(element => {
          if (element.material == 2) {
            const index = this.teeths.findIndex(i => i.teethId == element.teeth)
            if (index != -1) {
              if (this.teeths[index].teethColor.fill == this.showColor) {
                const index2 = this.getDentalWorks.findIndex(i => i.code == element.treatment)
                this.teeths[index].teethColor.fill = this.getDentalWorks[index2].color;
              }
            }
          }
        });
        return
      }
      this.getOrderItems.forEach(element => {
        if (element.material == 2) {
          const index = this.teeths.findIndex(i => i.teethId == element.teeth)
          if (index != -1) {
            if (this.teeths[index].teethColor.fill == this.showColor) {
              const index2 = this.getDentalWorks.findIndex(i => i.code == element.treatment)
              this.teeths[index].teethColor.fill = this.getDentalWorks[index2].color;
            } else {
              this.teeths[index].teethColor.fill = this.showColor;
            }

          }
        }
      });

    },
    showImplants(lastSet = false) {
      if (lastSet) {
        this.getOrderItems.forEach(element => {
          if (element.implant) {
            const index = this.teeths.findIndex(i => i.teethId == element.teeth)
            if (index != -1) {
              if (this.teeths[index].teethColor.fill == this.showColor) {
                const index2 = this.getDentalWorks.findIndex(i => i.code == element.treatment)
                this.teeths[index].teethColor.fill = this.getDentalWorks[index2].color;
              }
            }
          }
        });
        return
      }
      this.getOrderItems.forEach(element => {
        if (element.implant) {
          const index = this.teeths.findIndex(i => i.teethId == element.teeth)
          if (index != -1) {
            if (this.teeths[index].teethColor.fill == this.showColor) {
              const index2 = this.getDentalWorks.findIndex(i => i.code == element.treatment)
              this.teeths[index].teethColor.fill = this.getDentalWorks[index2].color;
            } else {
              this.teeths[index].teethColor.fill = this.showColor;
            }

          }
        }
      });

    },
    selectTeeth(teethId) {
      if (this.info) {
        return
      }
      const index = this.teeths.findIndex(i => i.teethId == teethId);
      if (this.teeths[index].teethColor.fill == 'white') {
        if ((this.getDentalCredit - this.getTotalTreatmentCredit - this.getWorkToDo.credit) < 0) {
          this.$vs.notify({
            time: 4000,
            color: "warning",
            title: this.$t('Design_Message_13'),
            text: this.$t('Dental_Message_1')
          });
          return
        }
        this.teeths[index].teethColor.fill = this.getWorkToDo.color;
        if (this.getImplant.implant) {
          this.teeths[index].haveImplant = true
        }
        this.teeths[index].isSelected = !this.teeths[index].isSelected
        this.controlDots(teethId);

      } else {
        this.teeths[index].teethColor.fill = 'white';
        this.teeths[index].haveImplant = false
        this.teeths[index].isSelected = !this.teeths[index].isSelected
        this.controlDots(teethId);
      }
      var dentalWork = {
        teeth: teethId,
        work: this.getWorkToDo,
        materiel: this.getMateriel,
        implant: this.getImplant
      }
      this.$store.dispatch("dental/newWork", dentalWork);
    },
    changeDotStatus(dot) {
      const index = this.dots.findIndex(i => i.dotId == dot)
      this.dots[index].dotStyle.fill = this.changeColor(this.dots[index].dotStyle.fill)
      this.$store.dispatch("dental/changeTeethRelation", dot);
    },
    changeColor(color) {
      if (color == "green") {
        return "black";
      } else {
        return "green";
      }
    },
    controlDots(teethId) {
      var bigTeeth = null;
      const index = this.teeths.findIndex(i => i.teethId == teethId);
      //Büyük Taraf için Diş Seçimi
      if (index != 31) {
        if (this.teeths[index].teethId > this.teeths[index + 1].teethId) {
          bigTeeth = this.teeths[index].teethId;
        } else {
          bigTeeth = this.teeths[index + 1].teethId;
        }
      } else {
        bigTeeth = this.teeths[index].teethId;
      }
      if (
        index != 31 &&
        this.teeths[index].isSelected == true &&
        this.teeths[index + 1].isSelected == true
      ) {
        this.dots.forEach(element => {
          if (element.dotId == bigTeeth) {
            element.dotStyle.visibility = "visible";
            element.dotStyle.fill = "black";
            return;
          }
        });
      }
      if (
        (index != 31 &&
          this.teeths[index].isSelected == false &&
          this.teeths[index + 1].isSelected == true) ||
        (index != 31 &&
          this.teeths[index].isSelected == true &&
          this.teeths[index + 1].isSelected == false)
      ) {
        this.dots.forEach(element => {
          if (element.dotId == bigTeeth) {
            element.dotStyle.visibility = "hidden";
            return;
          }
        });
      }
      //Kücük Taraf için Diş Seçimi
      if (index != 0) {
        if (this.teeths[index].teethId > this.teeths[index - 1].teethId) {
          bigTeeth = this.teeths[index].teethId;
        } else {
          bigTeeth = this.teeths[index - 1].teethId;
        }
      } else {
        bigTeeth = this.teeths[index].teethId;
      }
      // 0 >  48 in secilmesi
      // 16 > 28 in secilmesi
      if (
        index != 0 &&
        index != 16 &&
        this.teeths[index].isSelected == true &&
        this.teeths[index - 1].isSelected == true
      ) {
        this.dots.forEach(element => {
          if (element.dotId == bigTeeth) {
            element.dotStyle.visibility = "visible";
            element.dotStyle.fill = "black";
            return;
          }
        });
      }
      if (
        (index != 0 &&
          index != 16 &&
          this.teeths[index].isSelected == false &&
          this.teeths[index - 1].isSelected == true) ||
        (index != 0 &&
          index != 16 &&
          this.teeths[index].isSelected == true &&
          this.teeths[index - 1].isSelected == false)
      ) {
        this.dots.forEach(element => {
          if (element.dotId == bigTeeth) {
            element.dotStyle.visibility = "hidden";
            return;
          }
        });
      }

      if (!this.firstLoading) {
        this.getOrder.teeth_relation.forEach(element => {
          const index = this.dots.findIndex(i => i.dotId == element)
          if (this.dots[index].dotStyle.visibility == 'hidden') {
            this.$store.dispatch("dental/changeTeethRelation", element);
          }
        });
      }
    },
    initPage() {
      this.getDentalWork.forEach(element => {
        const index = this.teeths.findIndex(i => i.teethId == element.teeth);
        this.teeths[index].teethColor.fill = element.work.color;
        this.teeths[index].isSelected = true;
        if (element.implant.implant) {
          this.teeths[index].haveImplant = true;
        }
        this.controlDots(element.teeth);
      });

      this.firstLoading = false;
    },
    initDots() {
      this.getTeethRelations.forEach(element => {
        const index = this.dots.findIndex(i => i.dotId == element)
        this.dots[index].dotStyle.fill = this.changeColor(this.dots[index].dotStyle.fill)
      });
    },
    initOrder(newRequest = true) {
      if (this.getDentalWork.length && newRequest) {
        return
      }
      this.getOrderItems.forEach(element => {
        const index = this.getDentalWorks.findIndex(i => i.code == element.treatment)

        var dentalWork = {
          teeth: element.teeth,
          work: {
            code: this.getDentalWorks[index].code,
            color: this.getDentalWorks[index].color,
            credit: element.credit,
            name: this.getDentalWorks[index].name,
          },
          materiel: element.material,
          implant: {
            implant: element.implant,
            brand: element.implant_brand,
            model: element.implant_model,
          }
        }
        this.$store.dispatch("dental/newWork", dentalWork);
      });
    },
    paintTeeth() {
      if (typeof this.justShow == 'undefined') {
        return
      }
      this.justShow.forEach(element => {
        const index = this.teeths.findIndex(i => i.teethId == element.teeth);
        const indexColor = this.getDentalWorks.findIndex(i => i.code == element.treatment)
        this.teeths[index].teethColor.fill = this.getDentalWorks[indexColor].color
        this.teeths[index].isSelected = true;
        if (element.implant) {
          this.teeths[index].haveImplant = true;
        }
        this.controlDots(element.teeth);
      });
      this.initDots();
    }
  },
  created() {
    if (typeof this.justShow != 'undefined') {
      this.paintTeeth();
      return
    }
    if (typeof this.$route.query.id != "undefined") {
      this.initOrder()
    }
    if (this.getOrder.order_items.length > 0) {
      this.initPage();
      this.initDots();
    }
  },
};